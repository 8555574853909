import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { FaqImpressionEvent } from '@common/analytics/events/faq-impression.event';
import { AnalyticsService } from '@common/analytics/services/analytics.service';
import { WINDOW } from '@common/services/window.service';
import { isInViewport$ } from '@common/util/dom';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ContentService } from '../../services/content.service';
import { FaqCategory, FaqItem } from '../faq/faq.component.base';

@Component({
    selector: 'app-mini-faq',
    templateUrl: './mini-faq.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniFaqComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() public category: string = null;

    @Input() public params = [];

    @ViewChild('accordion') accordion: NgbAccordionDirective;

    private itemsSubscription: Subscription = null;
    private itemsSubject: Subject<FaqItem[]> = new BehaviorSubject<FaqItem[]>([]);
    public items$: Observable<FaqItem[]> = this.itemsSubject.asObservable();

    private destroyed$ = new Subject<void>();


    get body(): Element {
        return this.window?.document?.body;
    }

    constructor(
        public contentService: ContentService,
        private analyticsService: AnalyticsService,
        @Inject(WINDOW) private window: Window,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.category && changes.category.previousValue !== changes.category.currentValue) {
            if (this.itemsSubscription) {
                this.itemsSubscription.unsubscribe();
                this.itemsSubscription = null;
            }

            if (changes.category.currentValue) {
                const faqCategory = new FaqCategory(changes.category.currentValue.replace('faq-category-', ''));
                this.itemsSubscription = faqCategory.getItemKeys(this.contentService)
                    .pipe(
                        takeUntil(this.destroyed$),
                        filter((itemsKeys) => !!itemsKeys),
                        map((itemsKeys) => {
                            return itemsKeys.map((itemKey) => new FaqItem(itemKey.replace('faq-', ''), [changes.category.currentValue.id]));
                        }),
                    )
                    .subscribe((faqItems) => {
                        this.itemsSubject.next(faqItems);
                    });
            } else {
                this.itemsSubject.next([]);
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }


    /* Analytics */
    eventsSentIds: Set<string> = new Set();

    ngAfterViewInit(): void {
        this.accordion?.shown.subscribe((panelId: string) => {
            const panelElement = document.getElementById(panelId);
            if (panelElement && !this.eventsSentIds.has(panelId)) {
                this.sendAnalyticsEventWhenInViewport(panelElement);
            }
        })
    }

    private sendAnalyticsEventWhenInViewport(el: Element): void {
        isInViewport$(el, { threshold: 0.5 }) // 50% of the faq panel should be visible to trigger
            .pipe(filter(Boolean), take(1)) //we just need one true value before completing
            .subscribe(() => {
                this.sendAnalyticsEvent(el.id);
                this.eventsSentIds.add(el.id);
            });
    }

    private sendAnalyticsEvent(id: string): void {
        const event = FaqImpressionEvent.create({ faq_cms_key: FaqItem.getKey(id)});
        this.analyticsService.push(event);
    }
}
