<ng-container *ngIf="items$ | async as items">
    <div
        ngbAccordion
        [closeOthers]="false"
        [destroyOnHide]="false"
        #accordion="ngbAccordion"
    >
        <ng-container *ngFor="let item of items">
            <div
                ngbAccordionItem="{{ item.id }}"
                #currentItem="ngbAccordionItem"
            >
                <div ngbAccordionHeader>
                    <button
                        ngbAccordionButton
                        class="d-flex text-start"
                        [attr.data-testid]="item.key"
                    >
                        <span
                            class="ml-3"
                            [innerHTML]="
                                item.getContent(contentService)
                                    | async
                                    | faqheader
                                    | paramReplacement : params
                            "
                        ></span>
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <div
                            routeTransformer
                            [innerHTML]="
                                item.getContent(contentService)
                                    | async
                                    | faqcontent
                                    | paramReplacement : params
                            "
                            routeTransformer
                            [routeTransformerArgs]="[]"
                        ></div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
