<div class="container">
    <div class="row mb-3 align-items-center">
        <img
            class="h-25 w-100"
            [attr.src]="'hero-image' | cmsImageSrc | async"
            [attr.alt]="'' | cmsImageAlt | async"
        />
        <div class="position-absolute">
            <h1 class="hero-title">{{ 'faq-title' | cmsText | async }}</h1>
        </div>
    </div>
    <div *ngIf="categories" class="row mb-3">
        <div class="col-md">
            <label for="categoryId" class="form-label">
                <app-content key="category-label"></app-content>
            </label>
            <select
                id="categoryId"
                name="categoryId"
                class="form-control"
                [(ngModel)]="categoryId"
                (ngModelChange)="categoryChange($event)"
                [disabled]="categoryDisabled"
            >
                <option *ngFor="let category of categories" [value]="category.id">
                    {{ category.getHeader(content) | async }}
                </option>
            </select>
        </div>
        <div class="col-md">
            <label for="searchTerm" class="form-label">
                <app-content key="search-label"></app-content>
            </label>
            <input
                class="form-control"
                id="searchTerm"
                name="searchTerm"
                (keyup)="search($event)"
                [(ngModel)]="searchTerm"
            />
        </div>
    </div>

    <ng-container *ngIf="currentItems$ | async as currentItemsSync">
        <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="false">
            <ng-container *ngFor="let item of currentItemsSync">
                <div ngbAccordionItem="{{ item.id }}" #currentItem="ngbAccordionItem">
                    <div ngbAccordionHeader>
                        <button ngbAccordionButton class="d-flex text-start">
                            <span
                                [highlight]="searchTerm"
                                routeTransformer
                                [innerHTML]="
                                    item.getContent(content)
                                        | async
                                        | faqheader
                                        | paramReplacement : faqParams
                                "
                            ></span>
                        </button>
                    </div>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                            <div
                                [highlight]="searchTerm"
                                routeTransformer
                                [innerHTML]="
                                    item.getContent(content)
                                        | async
                                        | faqcontent
                                        | paramReplacement : faqParams
                                "
                            ></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div
            class="alert alert-warning"
            [innerHTML]="'search-no-results-message' | cmsText | async"
            *ngIf="currentItemsSync?.length === 0"
        ></div>
    </ng-container>
</div>

<app-register-now-banner></app-register-now-banner>
